import * as amplitude from '@amplitude/analytics-browser';
import { AnalyticsContext, AnalyticsContextState } from './AnalyticsContext';
import { useEffect } from 'react';
import { ComponentWithChildrenProps } from './props';

type AmplitudeAnalyticsProviderProps = ComponentWithChildrenProps & {
  apiKey: string;
};

const amplitudeAnalytics: AnalyticsContextState = {
  setUser: (id) => {
    amplitude.setUserId(id);
  },
  trackEvent: (name, data) => {
    amplitude.track(name, data);
  },
};

export const AmplitudeAnalyticsProvider = ({
  apiKey,
  children,
}: AmplitudeAnalyticsProviderProps) => {
  useEffect(() => {
    amplitude.init(apiKey, {
      autocapture: {
        attribution: false,
        pageViews: false,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: false,
      },
    });
  }, [apiKey]);

  return (
    <AnalyticsContext.Provider value={amplitudeAnalytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
