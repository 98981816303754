import { Context as ReactContext, useContext, createContext } from 'react'

export function createContextHook<T, R = T>(
    Context: ReactContext<T | undefined>,
    contextName: string,
    transform?: (context: T) => R,
): () => R {
    return (): R => {
        const context = useContext(Context)

        if (!context) {
            throw new Error(`${contextName} is not provided`)
        }

        return transform ? transform(context) : (context as unknown as R)
    }
}

export type AnalyticsContextState = {
    setUser: (id: string) => void
    trackEvent: (name: string, data?: Record<string, string|number|boolean|null>) => void
}

export const AnalyticsContext = createContext<
    AnalyticsContextState | undefined
>(undefined)

export const useAnalytics = createContextHook(
    AnalyticsContext,
    'AnalyticsContext',
)