import { ComponentWithChildrenProps } from '@/Analytics/props';
import { AmplitudeAnalyticsProvider } from '@/Analytics/AmplitudeAnalyticsProvider';
import { LocalAnalyticsProvider } from '@/Analytics/LocalAnalyticsProvider';

export function shouldBeDefined<T>(
  value: T | undefined,
  valueName: string = 'value'
): T {
  if (value === undefined) {
    throw new Error(`${valueName} is undefined`);
  }

  return value;
}

export const AnalyticsProvider = ({ children }: ComponentWithChildrenProps) => {
  if (process.env.NODE_ENV === 'production') {
    return (
      <AmplitudeAnalyticsProvider
        apiKey={shouldBeDefined(import.meta.env.VITE_PUBLIC_AMPLITUDE_API_KEY)}
      >
        {children}
      </AmplitudeAnalyticsProvider>
    );
  }

  return <LocalAnalyticsProvider>{children}</LocalAnalyticsProvider>;
};
